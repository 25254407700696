import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {WebpageItem} from "../types";
import {fetchService} from "../Widget/utils";
import {WebpageStatus} from "../enums";
import config from "../config";

interface TrainingDataState {
    webpages: WebpageItem[];
    loading: boolean;
    error: string | null;
}

const initialState: TrainingDataState = {
    webpages: [],
    loading: false,
    error: null,
};

// Add this interface at the beginning of your trainingDataSlice file
interface UpdateWebpageStatusPayload {
    id: string;
    status: WebpageStatus;
}

export const trainingDataSlice = createSlice({
    name: 'trainingData',
    initialState,
    reducers: {
        updateWebpages: (state, action: PayloadAction<WebpageItem[]>) => {
            // Directly update the state with the new URLs, ensuring no duplicates
            action.payload.forEach(newItem => {
                const existingWebpage = state.webpages.find(webpage => webpage.url === newItem.url);
                if (existingWebpage) {
                    // Update existing item properties
                    Object.assign(existingWebpage, newItem);
                } else {
                    // Add new item
                    state.webpages.push(newItem);
                }
            });
        },
        updateStatusFromServer: (state, action: PayloadAction<WebpageItem[]>) => {
            console.log('updateStatusFromServer', action.payload);
            // Update the state with the new status from the server
            action.payload.forEach((update) => {

                const index = state.webpages.findIndex((page) => page._id === update._id);
                if (index !== -1) {
                    state.webpages[index] = {
                        ...state.webpages[index],
                        ...update
                    };
                    // Update other fields as necessary
                }
            });
        },
        updateWebpageStatus: (state, action: PayloadAction<UpdateWebpageStatusPayload>) => {
            const { id, status } = action.payload;
            const webpageIndex = state.webpages.findIndex((webpage) => webpage._id === id);
            if (webpageIndex !== -1) {
                state.webpages[webpageIndex].status = status;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWebpages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWebpages.fulfilled, (state, action) => {
                state.loading = false;
                state.webpages = action.payload;
            })
            .addCase(fetchWebpages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(deleteWebpage.fulfilled, (state, action) => {
                // Remove the webpage from the state
                state.webpages = state.webpages.filter((page) => page._id !== action.meta.arg);
            })
            .addCase(deleteWebpage.rejected, (state, action) => {
                // Handle any errors, possibly setting an error state field
                state.error = action.payload || 'An error occurred';
            })
    }

});

export default trainingDataSlice.reducer;

export const { updateWebpages, updateStatusFromServer, updateWebpageStatus } = trainingDataSlice.actions;

export const fetchWebpages = createAsyncThunk(
    'trainingData/fetchWebpages',
    async (_, { rejectWithValue }) => {
        try {
            // Replace this with the actual API call
            const webpages = await fetchService(`${config.API_ENDPOINT}/scrape/webpages`, 'GET');
            return webpages as WebpageItem[];
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
});

export const deleteWebpage = createAsyncThunk(
    'trainingData/deleteWebpage',
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/scrape/webpage/delete/${id}`, 'DELETE');
            // You may want to check the response here and return or throw appropriately
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
