import React, {useEffect, useRef} from 'react';
import { useAuth } from '../AuthContext';
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import { fetchSubscriptions } from "../store/subscriptionsSlice";

const Settings = () => {
    const { orgId, email, logout } = useAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { subscriptions, loading, error } = useAppSelector(
        state => state.subscriptions);

    const isFetchingSubscriptions = useRef(false);

    useEffect(() => {
        if (!loading && !isFetchingSubscriptions.current) {
            isFetchingSubscriptions.current = true;
            dispatch(fetchSubscriptions())
                .finally(() => {
                    isFetchingSubscriptions.current = false;
                });
        }
    }, [dispatch, loading]);

    const handleLogout = () => {
        logout(() => navigate('/login'));
    };



    return (
        <div className="container mx-auto p-4 bg-white">
            <h1 className="text-2xl font-semibold mb-4">Settings</h1>
            <div className="p-4 mb-8 border border-gray-200 rounded">
                <h2 className="text-xl mb-2 text-orange-600">Account Info</h2>
                <div className="mb-8">
                    <h3 className="text-sm mb-2">Organization ID</h3>
                    <p className="text-gray-700">{orgId}</p>
                </div>
                <div className="mb-8">
                    <h3 className="text-sm mb-2">Registered Email</h3>
                    <p className="text-gray-700">{email}</p>
                </div>
            </div>
            <div className="p-4 mb-8 border border-gray-200 rounded">
                <h2 className="text-xl mb-2 text-orange-600">Account Actions</h2>
                <button onClick={handleLogout}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
                    Log Out
                </button>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
                    Change Password
                </button>
                <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded m-2 opacity-70">
                    Delete Account
                </button>
            </div>
        </div>
    );
};

export default React.memo(Settings);