import config from "../config";

export function generateRandomBytes(length) {
    const bytes = [];
    for (let i = 0; i < length; i++) {
        bytes.push(Math.floor(Math.random() * 256));
    }
    return bytes;
}

export function generateObjectId() {
    const timestamp = Math.floor(Date.now() / 1000); // Get current timestamp in seconds
    const processId = generateRandomBytes(5); // Generate 5 random bytes
    const counter = generateRandomBytes(3); // Generate 3 random bytes

    // Concatenate the components in the correct order
    const objectId = timestamp.toString(16).padStart(8, '0') +
        processId.map(byte => byte.toString(16).padStart(2, '0')).join('') +
        counter.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return objectId;
}

export function getQueryParams(url){
    const urlObject = new URL(`${config.CHAT_ENDPOINT}/?id=andy&origin=https://orbichat.local`);
    const params = new URLSearchParams(url.search);
}

export function getSearchParams(url){
    const urlObject = new URL(url);
    return new URLSearchParams(url.search);
}

export function decodeToken(token: string) {
    if (!token) return null;
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    return decodedToken;
}