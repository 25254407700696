import React, { createContext, useState, useContext } from 'react';
import {decodeToken} from "./Utils";

interface AuthContextProps {
    isLoggedIn: boolean;
    email: '',
    orgId: '',
    login: (tokens:{access_token: string, refresh_token: string}) => void;
    logout: (navigate: () => void) => void;
}

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const tokens = JSON.parse(localStorage.getItem('tokens') || '{}');
    const decodedToken = decodeToken(tokens.access_token);
    const [isLoggedIn, setIsLoggedIn] = useState(!!tokens.access_token);
    const [email, setEmail] = useState(decodedToken?.email || '');
    const [orgId, setOrgId] = useState(decodedToken?.orgId || '');

    const login = (tokens: {access_token: string, refresh_token: string}) => {
        localStorage.setItem('tokens', JSON.stringify(tokens));
        setIsLoggedIn(true);
        const decodedToken = decodeToken(tokens.access_token);
        console.log('access_token:', tokens.access_token); // Add this line
        console.log('decodedToken:', decodedToken); // Add this line
        setEmail(decodedToken.email);
        setOrgId(decodedToken.orgId);
    };

    const logout = (navigate: (route: string) => void) => {
        localStorage.removeItem('tokens');
        setIsLoggedIn(false);
        setEmail('');
        setOrgId('');
        navigate('/login')
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, email, orgId, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth(): AuthContextProps {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
