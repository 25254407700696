import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
    fetchChatLogs,
    fetchChatDetails,
    selectChat
} from '../store/chatLogSlice';

import { UserCircleIcon } from '@heroicons/react/24/solid';
import Logo from "../components/Logo";

const ChatLog = () => {
    const dispatch = useAppDispatch();
    const chats = useAppSelector((state) => state.chatLog.chats);
    const selectedChatId = useAppSelector((state) => state.chatLog.selectedChatId);
    const chatDetails = useAppSelector((state) => state.chatLog.chatDetails);
    const loading = useAppSelector((state) => state.chatLog.loading);

    useEffect(() => {
        dispatch(fetchChatLogs());
    }, [dispatch]);

    const handleSelectChat = (chatId) => {
        dispatch(selectChat(chatId));
        dispatch(fetchChatDetails(chatId));
    };

    return (
        <div className="container mx-auto p-8 bg-gray-100 max-w-7xl">
            <div className="flex h-screen">
                {/* Chat list on the left */}
                <div className="w-1/3 overflow-y-auto p-4 border-r">
                    <h2 className="text-lg font-bold mb-4">Conversations</h2>
                    <div className="space-y-2">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            chats.map((chat) => (
                                <div
                                    key={chat._id}
                                    onClick={() => handleSelectChat(chat._id)}
                                    className={`p-4 my-2 cursor-pointer rounded-lg ${selectedChatId === chat._id ? 'bg-green-100' : 'hover:bg-green-50'}`}
                                >
                                    {/* Display the title or identifier of the chat here */}
                                    <p className="truncate font-medium text-gray-700">Visitor {chat._id.substring(0, 5)}</p>
                                    <p className="text-sm text-gray-500 truncate">This is the basic synopsis</p>
                                    {/*<p className="text-sm text-gray-500 truncate">{chat.messages[0].text}</p>*/}
                                </div>
                            ))
                        )}
                    </div>
                </div>

                {/* Chat details on the right */}
                <div className="w-2/3 p-4">
                    {selectedChatId && chatDetails ? (
                        <div className="h-full flex flex-col">
                            <h3 className="text-lg font-bold mb-4">Visitor {selectedChatId.substring(0, 5)}</h3>
                            <div className="flex-1 overflow-y-auto flex-col">
                                {chatDetails.messages.map((msg, index) => (
                                    <div key={index} className={`p-4 my-2 rounded-lg ${msg.role === 'user' ? 'bg-white mr-auto' : 'bg-green-100 ml-auto'} max-w-[70%]`}>
                                        <div className={`p-4 rounded-lg ${msg.role === 'user' ? 'bg-white' : 'bg-green-100'}`}>
                                            {msg.text}
                                        </div>
                                        <div className={`flex items-center mt-1 ${msg.role === 'user' ? 'justify-start' : 'justify-end'}`}>
                                            {msg.role === 'user' ? (
                                                <>
                                                    <UserCircleIcon className="h-5 w-5 text-gray-500" />
                                                    <span className="text-xs text-gray-500 ml-2">
                                        {new Date(msg.time).toLocaleDateString()} {new Date(msg.time).toLocaleTimeString()}
                                    </span>
                                                </>
                                            ) : (
                                                <Logo className="h-5 w-5" /> // Adjust the size as needed
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p>Select a conversation to view the chat details.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatLog;
