import React, { useEffect, useState } from 'react';
import config from "../config";

const ChatIframe = ({ orgId, iframeKey }) => {
    const [iframeUrl, setIframeUrl] = useState('');

    console.log('rerender ChatIframe');
    useEffect(() => {
        console.log('rerender useEffect');
        const origin = window.location.origin;
        const params = new URLSearchParams({
            orgId: orgId,
            origin,
            launch: 'true',
        }).toString();
        setIframeUrl(`${config.CHAT_ENDPOINT}?${params}`);
    }, [orgId]);

    return iframeUrl ? (
        <iframe
            id="chatbot-preview"
            src={iframeUrl}
            style={{ width: '420px', height: '680px', border: 'none', boxShadow: '1px 1px 10px lightgrey', borderRadius: '10px', position: 'fixed', bottom: '80px', right: '30px', zIndex: '9999' }}
            title="Chatbot Preview"
            key={iframeKey}
        />
    ) : null;
};

export default React.memo(ChatIframe);;