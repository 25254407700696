    import React from 'react';
    import { BrowserRouter as Router, Route, Routes, Navigate, RouteProps } from 'react-router-dom';
    import { AuthProvider, useAuth } from './AuthContext';
    import { ConfigProvider } from './ConfigContext';
    import Dashboard from './pages/Dashboard';
    import Login from './pages/Login';
    import Signup from './pages/Signup';
    import TrainingData from "./pages/TrainingData";
    import ChatLog from "./pages/ChatLog";
    import FAQ from "./pages/FAQ";
    import Appearance from "./pages/Appearance";
    import Settings from "./pages/Settings";
    import Summary from "./pages/Summary";

    const AppRoutes: React.FC = () => {
        const { isLoggedIn } = useAuth();
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                    path="builder/*"
                    element={isLoggedIn ? <Dashboard basePath="/builder"/> : <Navigate to="/login" />}
                >
                    {/* Nested routes */}
                    <Route index element={<h1 className="text-2xl font-semibold">Overview</h1>} />
                    <Route path="overview" element={<h1 className="text-2xl font-semibold">Overview</h1>} />
                    <Route path="web" element={<TrainingData />} />
                    <Route path="summary" element={<Summary />} />
                    <Route path="faq" element={<FAQ />} />
                    <Route path="chats" element={<ChatLog />} />
                    <Route path="appearance" element={<Appearance />} />
                    <Route path="settings" element={<Settings />} />
                </Route>
            </Routes>
        );
    };

    const App: React.FC = () => {
        return (
            <ConfigProvider>
                <AuthProvider>
                    <Router>
                        <AppRoutes />
                    </Router>
                </AuthProvider>
            </ConfigProvider>

        );
    };

    export default App;
