import React from 'react';
import { Routes, Route, Outlet, Link, useLocation } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/outline';
import Logo from "../components/Logo";

interface DashboardProps {
    basePath: string;
}

const Dashboard: React.FC<DashboardProps> = ({basePath}) => {
    const location = useLocation();

    const isActive = (path: string) => {
        return location.pathname.includes(path) ? 'bg-orbiOrange-100 font-bold' : '';
    }

    return (
        <div className="flex h-screen bg-white">
            {/* Sidebar */}
            <div className="bg-black w-64 flex-shrink-0 p-8 border-r">
                {/* OrbiChat Logo and Name */}
                <div className="flex items-center space-x-2">
                    <Logo/>
                    <span className="text-xl font-bold text-white">OrbiChat</span>
                </div>

                <ul className="space-y-4">
                    <li className={`p-2 rounded-md ${isActive(`${basePath}/overview`)}`}>
                        <Link to={`${basePath}/overview`}
                              className="flex items-center space-x-2 text-white hover:text-orbiOrange-800">
                            <HomeIcon className="h-5 w-5 mr-2 text-white"/>
                            <span>Overview</span>
                        </Link>
                    </li>

                    <li>
                        <div className="mt-5 mb-2 font-semibold text-lg text-white">Training Data</div>
                        <ul className="pl-5 space-y-4" role="group">
                            <li className={`p-2 rounded-md ${isActive(`${basePath}/web`)}`}>
                                <Link to={`${basePath}/web`} className="text-white hover:text-orange-200 block py-1">
                                    Web Pages</Link>
                            </li>
                            <li className={`p-2 rounded-md ${isActive(`${basePath}/summary`)}`}>
                                <Link to={`${basePath}/summary`} className="text-white hover:text-orange-200 block py-1">
                                    Summary</Link>
                            </li>
                            <li className={`p-2 rounded-md ${isActive(`${basePath}/faq`)}`}>
                                <Link to={`${basePath}/faq`} className="text-white hover:text-orange-200 block py-1">
                                    Q & A</Link>
                            </li>
                        </ul>
                    </li>

                    <li className={`p-2 rounded-md ${isActive(`${basePath}/chats`)}`}>
                        <Link to={`${basePath}/chats`}
                              className="text-white hover:text-orange-200 block py-1">Chats</Link>
                    </li>

                    <li className={`p-2 rounded-md ${isActive(`${basePath}/leads`)}`}>
                        <Link to={`${basePath}/leads`}
                              className="text-white hover:text-orange-200 block py-1">Leads</Link>
                    </li>

                    <li className={`p-2 rounded-md ${isActive(`${basePath}/design`)}`}>
                        <Link to={`${basePath}/appearance`}
                              className="text-white hover:text-orange-200 block py-1">Appearance</Link>
                    </li>
                    <li className={`p-2 rounded-md ${isActive(`${basePath}/settings`)}`}>
                        <Link to={`${basePath}/settings`}
                              className="text-white hover:text-orange-200 block py-1">Settings</Link>
                    </li>
                </ul>
            </div>

            {/* Main Content */}
            <div className="flex-1 overflow-auto">
                <Outlet/>
            </div>
        </div>
    );
};

export default Dashboard;
