import React, { useState, useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import { updateSettings } from "../store/settingsSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {useAuth} from "../AuthContext";

const GradientSlider = ({ gradient, onChange }) => {
    const dispatch = useAppDispatch();
    const { orgId } = useAuth();


    const handleChange = async (event) => {
        await dispatch(updateSettings({ orgId, settings: { gradient: Number(event.target.value) as (-1 | 0 | 1) } }));
        onChange();
    };

    return (
        <div className="relative">
        <input
            type="range"
            min="-1"
            max="1"
            step="1"
            value={gradient}
            onChange={handleChange}
            className="w-full"
        />
        <div className="flex justify-between text-sm text-gray-600 mt-2">
            <span>Darker</span>
            <span>None</span>
            <span>Lighter</span>
        </div>
    </div>)
};

export default GradientSlider;