import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";



interface UpgradePopupProps {
    show: boolean;
    onClose?: () => void;
    message: string;
}
const UpgradePopup: React.FC<UpgradePopupProps> = ({ show, onClose, message }) => {

    if (!show) {
        return null;
    }

    const content = (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg relative">
                <button onClick={onClose} className="absolute top-2 right-2">
                    ❌
                </button>
                <div className="upgrade-popup">
                    <h2>Upgrade Required</h2>
                    <p>{message}</p>
                    <a href="/upgrade">Upgrade Now</a>
                </div>
            </div>
        </div>

        )
    ;

    return ReactDOM.createPortal(content, document.getElementById('portal')!);
};

export default UpgradePopup;