import React, { useState, useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import { updateSettings } from "../store/settingsSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {useAuth} from "../AuthContext";
import Bot1Icon from "./icons/Bot1Icon";
import Person1Icon from "./icons/Person1Icon";
import Question1Icon from "./icons/Question1Icon";
import Info1Icon from "./icons/Info1Icon";
import Chat1Icon from "./icons/Chat1Icon";

const LaunchBtnPicker = ({ iconId, color, onChange }) => {
    const dispatch = useAppDispatch();
    const { orgId } = useAuth();


    const handleChange = async (iconId: string) => {
        await dispatch(updateSettings({ orgId, settings: { iconId: iconId } }));
        onChange();
    };

    return (
        <div className="flex space-x-4">
            <label>
                <input
                    type="radio"
                    value="bot_1"
                    checked={iconId === 'bot_1'}
                    onChange={() => handleChange('bot_1')}
                />
                <Bot1Icon className="h-10 w-10" fill={color} />
            </label>
            <label>
                <input
                    type="radio"
                    value="person_1"
                    checked={iconId === 'person_1'}
                    onChange={() => handleChange('person_1')}
                />
                <Person1Icon className="h-10 w-10" fill={color} />
            </label>
            <label>
                <input
                    type="radio"
                    value="question_1"
                    checked={iconId === 'question_1'}
                    onChange={() => handleChange('question_1')}
                />
                <Question1Icon className="h-10 w-10" fill={color} />
            </label>
            <label>
                <input
                    type="radio"
                    value="info_1"
                    checked={iconId === 'info_1'}
                    onChange={() => handleChange('info_1')}
                />
                <Info1Icon className="h-10 w-10" fill={color} />
            </label>
            <label>
                <input
                    type="radio"
                    value="chat_1"
                    checked={iconId === 'chat_1'}
                    onChange={() => handleChange('chat_1')}
                />
                <Chat1Icon className="h-10 w-10" fill={color} />
            </label>
        </div>)
};

export default LaunchBtnPicker;