import React from 'react';

interface TitleBarProps {
    name: string;
}

const TitleBar = ({name}) => {
    return (
        <div className="mb-1 flex justify-between items-center">
            <h1 className="text-xl text-orbiOrange">{name}</h1>
        </div>
    );
}

export default TitleBar;
