import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchService } from "../Widget/utils";
import config from "../config";

interface AnswerSchema {
    answer: { type: String, required: true },
    time: { type: Date, required: true }  // Renamed from timestamp to time
};



interface FAQsItem {
    _id: string;
    orgId: string;
    question: string;
    generatedAnswer: AnswerSchema;
    previousAnswer: AnswerSchema;
    customAnswer: AnswerSchema;
    favAnswer: string;
    status: 'none' | 'pending' | 'generated' | 'failed';
}

interface FAQsState {
    faqs: FAQsItem[];
    selectedFAQId: string | null;
    loadingFetch: boolean;
    loadingGenerate: boolean;
    error: string | null;
}

const initialState: FAQsState = {
    faqs: [],
    selectedFAQId: null,
    loadingFetch: false,
    loadingGenerate: false,
    error: null,
};

// Async thunk for generating FAQs
export const generateInitialQuestions = createAsyncThunk(
    'faqs/generateInitialQuestions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/faqs/generate`, 'POST');
            return response as FAQsItem[];
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const generateAdditionalQuestions = createAsyncThunk(
    'faqs/generateAdditionalQuestions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/faqs/generate/additional`, 'POST');
            return response as FAQsItem[];
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk for fetching FAQs
export const fetchFAQs = createAsyncThunk(
    'faqs/fetchFAQs',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/faqs`, 'GET');
            return response as FAQsItem[];
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const generateAnswers = createAsyncThunk(
    'faqs/generateAnswers',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/faqs/generate_answers`, 'POST');
            return response as FAQsItem[];
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateFAQ = createAsyncThunk(
    'faqs/updateFAQ',
    async (faq: FAQsItem, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/faqs/${faq._id}`, 'PUT', faq);
            return response as FAQsItem;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const regenerateAnswer = createAsyncThunk(
    'faqs/regenerateAnswer',
    async (faqId: string, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/faqs/regenerate/${faqId}`, 'POST');
            return response as FAQsItem;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const clearAllFAQs = createAsyncThunk(
    'faqs/clearAllFAQs',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/faqs/clear`, 'DELETE');
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const faqSlice = createSlice({
    name: 'faqs',
    initialState,
    reducers: {
        selectFAQ: (state, action: PayloadAction<string>) => {
            state.selectedFAQId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(generateInitialQuestions.pending, (state) => {
                state.loadingGenerate = true;
                state.error = null;
            })
            .addCase(generateInitialQuestions.fulfilled, (state, action) => {
                state.loadingGenerate = false;
                state.faqs = action.payload;
            })
            .addCase(generateInitialQuestions.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.error = action.payload as string;
            })
            .addCase(generateAdditionalQuestions.pending, (state) => {
                state.loadingGenerate = true;
                state.error = null;
            })
            .addCase(generateAdditionalQuestions.fulfilled, (state, action) => {
                state.loadingGenerate = false;
                // Add the newly generated FAQs to the existing FAQs
                state.faqs = [...state.faqs, ...action.payload];
            })
            .addCase(generateAdditionalQuestions.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.error = action.payload as string;
            })
            .addCase(fetchFAQs.pending, (state) => {
                state.loadingFetch = true;
                state.error = null;
            })
            .addCase(fetchFAQs.fulfilled, (state, action) => {
                state.loadingFetch = false;
                state.faqs = action.payload;
                state.loadingGenerate = state.faqs.some(faq => faq.status === 'pending');
            })
            .addCase(fetchFAQs.rejected, (state, action) => {
                state.loadingFetch = false;
                state.error = action.payload as string;
            })
            .addCase(generateAnswers.pending, (state) => {
                state.loadingGenerate = true;
                state.error = null;
            })
            .addCase(generateAnswers.fulfilled, (state, action) => {
                state.faqs = action.payload;
                state.loadingGenerate = state.faqs.some(faq => faq.status === 'pending');
                // // Merge the fetched answers with the existing FAQs
                // action.payload.forEach((faqWithAnswer) => {
                //     const faq = state.faqs.find((faq) => faq.id === faqWithAnswer.id);
                //     if (faq) {
                //         faq.answer = faqWithAnswer.answer;
                //     }
                // });
            })
            .addCase(generateAnswers.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.error = action.payload as string;
            })
            .addCase(updateFAQ.pending, (state) => {
                state.loadingGenerate = true;
                state.error = null;
            })
            .addCase(updateFAQ.fulfilled, (state, action) => {
                state.loadingGenerate = false;
                // Find the updated FAQ in the state and replace it
                const index = state.faqs.findIndex((faq) => faq._id === action.payload._id);
                if (index !== -1) {
                    state.faqs[index] = action.payload;
                }
            })
            .addCase(updateFAQ.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.error = action.payload as string;
            })
            .addCase(regenerateAnswer.pending, (state) => {
                state.loadingGenerate = true;
                state.error = null;
            })
            .addCase(regenerateAnswer.fulfilled, (state, action) => {
                state.loadingGenerate = false;
                // Find the updated FAQ in the state and replace it
                const index = state.faqs.findIndex((faq) => faq._id === action.payload._id);
                if (index !== -1) {
                    state.faqs[index] = action.payload;
                }
            })
            .addCase(regenerateAnswer.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.error = action.payload as string;
            })
            .addCase(clearAllFAQs.pending, (state) => {
                state.loadingGenerate = true;
                state.loadingFetch = true;
                state.error = null;
            })
            .addCase(clearAllFAQs.fulfilled, (state) => {
                state.loadingGenerate = false;
                state.loadingFetch = false;
                state.faqs = [];
            })
            .addCase(clearAllFAQs.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.loadingFetch = false;
                state.error = action.payload as string;
            });
    }
});

export const { selectFAQ } = faqSlice.actions;
export default faqSlice.reducer;
