import React, {ReactNode, useEffect, useRef, useState} from 'react';
import TitleBar from "../components/TitleBar";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
    fetchFAQs,
    generateFAQs, // Assuming you have an action to generate FAQs
    generateAnswers, // Assuming you have an action to generate answers for the FAQs
    selectFAQ, updateFAQ, regenerateAnswer, generateInitialQuestions, generateAdditionalQuestions, clearAllFAQs
} from "../store/faqSlice";
import Popup from "../components/Popup";
import {useCheckFAQSubscription} from "../hooks/useCheckSubscription";
import UpgradePopup from "../components/UpgradePopup";
import Loader from "../components/OrbiLoader";

const FAQ: React.FC = () => {
    const dispatch = useAppDispatch();
    const { faqs, loadingFetch, loadingGenerate, error } = useAppSelector(state => state.faq);
    const [selectedFAQ, setSelectedFAQ] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [currentAnswer, setCurrentAnswer] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const canRegenerateAnswers = useCheckFAQSubscription();
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const [upradePopupMessage, setUpradePopupMessage] = useState('');
    const isFetchingFAQs = useRef(false);

    useEffect(() => {
        let interval = null;
        if (!loadingFetch && !isFetchingFAQs.current) {
            isFetchingFAQs.current = true;
            dispatch(fetchFAQs())
                .finally(() => {
                    isFetchingFAQs.current = false;
                });
        }

        // Only set up polling if there are FAQs without generated answers
        if (faqs.some(faq => faq.status === 'pending')) {
            // Set up polling - fetch FAQs every 30 seconds
            interval = setInterval(() => {
                dispatch(fetchFAQs());
            }, 10000);
        }

        // Clean up interval on unmount or when all FAQs have generated answers
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [dispatch, loadingFetch, faqs]);

    const handleSelectFAQ = (faq) => {
        console.log('Selected FAQ', faq);
        setSelectedFAQ(faq);
        // dispatch(selectFAQ(faqId));
    };

    const handleGenerateInitialQuestions = () => {
        dispatch(generateInitialQuestions()); // Dispatch an action to generate FAQs
    };

    const handleGenerateAnswers = () => {
        dispatch(generateAnswers()); // Dispatch an action to generate answers for the FAQs
    };

    const handleRegenerateAnswers = () => {
        dispatch(generateAnswers()); // Dispatch an action to generate answers for the FAQs
        return;
        if (!canRegenerateAnswers) {
            // Show the upgrade popup
            setShowUpgradePopup(true);
            setUpradePopupMessage('You need to upgrade to regenerate answers');
            console.log('canRegenerateAnswers',false);
        } else {
            console.log('canRegenerateAnswers',true);
            dispatch(generateAnswers());
        }
    }

    const handleEditFAQ = (faq) => { // New function for handling FAQ edit
        console.log('Edit FAQ', faq);
        setSelectedFAQ(faq);
        setCurrentAnswer(faq.customAnswer ? faq.customAnswer.answer : faq.generatedAnswer?.answer);
        setShowPopup(true);
    };

    const handleCreateCustomAnswer = () => {
        setIsEditable(true);
    };

    const handleAnswerChange = (event) => {
        setCurrentAnswer(event.target.value);
    };

    const handleSave = () => {
        // Save the current answer here
        const updatedFAQ = { ...selectedFAQ };
        updatedFAQ.customAnswer = { answer: currentAnswer, time: new Date() };

        // Dispatch the updateFAQ action with the updated FAQ
        dispatch(updateFAQ(updatedFAQ));

        setIsEditable(false);
    };

    const handleRegenerateAnswer = () => {
        if (selectedFAQ.customAnswer && !window.confirm('The custom answer will be overwritten. Are you sure you want to regenerate the answer?')) {
            return;
        }

        // Dispatch the regenerateAnswer action with the selected FAQ's _id
        dispatch(regenerateAnswer(selectedFAQ._id));
    };

    const allQuestionsAnswered = () => {
        return faqs.every(faq => faq.customAnswer || faq.generatedAnswer);
    };

    const handleGenerateMoreQuestions = () => {
        dispatch(generateAdditionalQuestions()); // Dispatch an action to generate more FAQs
    };

    const handleClearAll = () => {
        if (window.confirm('Are you sure you want to clear all questions and answers? This cannot be undone.')) {
            dispatch(clearAllFAQs());
        }
    };

    return (
        <div className="container mx-auto p-8 bg-white max-w-7xl">
            <TitleBar name='Questions & Answers'/>

            {loadingFetch ? (
                <div className="transition-opacity duration-1000 opacity-1">
                    <Loader/>
                </div>

                    ) : loadingGenerate ? (
                    <div className="border p-20 m-auto text-center transition-opacity duration-1000 opacity-1">
                        <p className="mt-8">Generation in progress. This may take
                            about a minute. Feel free to continue using the app, and check back shortly.
                        </p>
                        <div className="flex justify-center mt-8">
                            <Loader/>
                        </div>
                    </div>

                    ) : error ? (
                    <p>Error loading FAQs: {error}</p>
                    ) : faqs.length === 0 ? (
                    <>
                        <div className="mt-8 text-center border p-4 rounded">
                            <h2 className="text-3xl font-bold leading-tight">Collaboratively Enhance Your AI Assistant's
                                Knowledge</h2>
                            <p className="mt-4 text-lg leading-relaxed text-gray-700">
                                Generate a set of frequently asked questions tailored to your industry. The AI assistant
                                will provide answers based on your current website content, allowing you to see its
                                present response capabilities.</p>
                            <p className="mt-4 text-lg leading-relaxed text-gray-700">This feature fosters a
                                collaborative approach, helping
                                you identify and fill gaps in information. The ultimate goal is to ensure your AI
                                assistant is optimally equipped to handle customer inquiries with accuracy and
                                relevance.
                            </p>
                            <div className="flex justify-center items-center my-10"> {/* Adjust height as needed */}
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-10 rounded-md transition-all duration-300 ease-in-out shadow-lg"
                                    onClick={handleGenerateInitialQuestions}
                                >
                                    Generate Questions Related to Your Industry
                                </button>
                            </div>
                        </div>

                    </>
                    ) : (
                    <>
                        <div className="flex space-x-4">
                            {allQuestionsAnswered() ? (
                                <button
                                    className="bg-green-500 text-white px-6 py-3 rounded-lg text-lg mb-4"
                                    onClick={handleRegenerateAnswers}
                                >
                                    Regenerate Answers
                                </button>
                            ) : (
                                <button
                                    className="bg-green-500 text-white px-6 py-3 rounded-lg text-lg mb-4"
                                    onClick={handleGenerateAnswers}
                                >
                                    Answer Unanswered Questions
                                </button>
                            )}
                            <button
                                className="bg-blue-500 text-white px-6 py-3 rounded-lg text-lg mb-4"
                                onClick={handleGenerateMoreQuestions}
                            >
                                Generate More Questions
                            </button>
                            <button
                                className="bg-red-500 text-white px-6 py-3 rounded-lg text-lg mb-4"
                                onClick={handleClearAll}
                            >
                                Clear All Questions and Answers
                            </button>
                        </div>
                        <table className="min-w-full bg-white">
                            <tbody>
                            {faqs.map((faq, index) => (
                                <tr key={index} onClick={() => handleEditFAQ(faq)}>
                                    <td className="border-gray-200 py-2 relative group">
                                        <div className="p-4 border rounded-lg">
                                            <div className="font-bold">{index + 1}. {faq.question}</div>
                                            <div className="mt-2">
                                                {faq.customAnswer ?
                                                    faq.customAnswer.answer :
                                                    faq.generatedAnswer?.answer as any}
                                            </div>
                                        </div>
                                        <button
                                            className="absolute top-0 right-0 m-2 bg-blue-500 text-white px-2 py-1
                                            opacity-0 group-hover:opacity-100 rounded"
                                            onClick={() => handleEditFAQ(faq)}>Edit
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>


                    )}

                    <Popup show={showPopup} onClose={() => setShowPopup(false)}>
                        <div style={{width: '80vw', maxWidth: '800px'}}
                             className="mx-auto max-w-screen-md sm:w-auto h-[60vh]">
                            <div className="flex justify-between items-center">
                                <h2 className="text-2xl font-bold mb-4">Edit FAQ</h2>
                                {selectedFAQ && (selectedFAQ.customAnswer ? (
                                    <button className="bg-blue-500 text-white px-4 py-2 rounded"
                                            onClick={handleCreateCustomAnswer}>Edit Answer</button>
                                ) : (
                                    <button className="bg-blue-500 text-white px-4 py-2 rounded"
                                            onClick={handleCreateCustomAnswer}>Create Custom Answer</button>
                                ))}
                                {selectedFAQ && (
                                    <button className="bg-yellow-500 text-white px-4 py-2 rounded"
                                            onClick={handleRegenerateAnswer}>Regenerate Answer</button>
                                )}
                            </div>
                            {selectedFAQ && (
                                <div className="p-4 border rounded-lg relative h-4/5">
                                    <div className="font-bold">{selectedFAQ.question}</div>
                                    <textarea
                                        className="mt-2 w-full h-full"
                                        value={currentAnswer}
                                        onChange={handleAnswerChange}
                                        readOnly={!isEditable}
                                    />
                                    {isEditable && (
                                        <button
                                            className="absolute bottom-0 right-0 m-2 bg-green-500 text-white px-2 py-1 rounded"
                                            onClick={handleSave}>Save</button>
                                    )}
                                </div>
                            )}
                        </div>
                    </Popup>
                    <UpgradePopup show={showUpgradePopup} message={upradePopupMessage}
                                  onClose={() => setShowUpgradePopup(false)}/>
                </div>
            );
            };

            export default FAQ;
