import React, {useState, useEffect, useRef} from 'react';
import { useAuth } from '../AuthContext';
import {fetchSettings, Status, updateSettings} from "../store/settingsSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import Bot1Icon from "../components/icons/Bot1Icon";
import Person1Icon from "../components/icons/Person1Icon";
import Question1Icon from "../components/icons/Question1Icon";
import Info1Icon from "../components/icons/Info1Icon";
import Chat1Icon from "../components/icons/Chat1Icon";
import ChatIframe from "../components/ChatIframe";
import ColorPicker from "../components/ColourPicker";
import GradientSlider from "../components/GradientSlider";
import LaunchBtnPicker from "../components/LuanchBtnPicker";
import {WelcomeEditor} from "../components/WelcomeEditor";
import PopupToggle from "../components/PopupToggle";
import TitleBar from "../components/TitleBar";
import AvatarEditor from 'react-avatar-editor';
import {fetchService} from "../Widget/utils";
import Logo from "../components/Logo";
import config from "../config";

const Appearance = () => {
    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.settings);
    const hasInitializedSettings = useRef(false);
    const { orgId } = useAuth();
    const [iframeKey, setIframeKey] = useState(Date.now);
    const isFetching = useRef(false);
    const [showChatbot, setShowChatbot] = useState(true); // Add a new state variable for showChatbot
    const [avatar, setAvatar] = useState<File | null>(null);
    const [editor, setEditor] = useState<any>(null);
    const [isHovered, setIsHovered] = useState(false);
    const setEditorRef = (editor: any) => setEditor(editor);

    useEffect(() => {
        if (settings.status === Status.Fulfilled) {
            hasInitializedSettings.current = true; // Set the ref to true when the settings have been fetched
        }
        if (settings.status === Status.Idle && !isFetching.current) {
            isFetching.current = true;
            dispatch(fetchSettings(orgId));
        }
    }, [dispatch, orgId, settings.status]);

    const toggleChatbot = () => {
        setShowChatbot(prevShowChatbot => !prevShowChatbot);
    };

    const reloadIframe = () => {
        setIframeKey(Date.now);
    }

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'CLOSE_CHAT') {
                setShowChatbot(false);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const notifyIframe = (color: any) => {
        try {
            const iframe = document.getElementById('chatbot-preview') as HTMLIFrameElement;
            iframe.contentWindow?.postMessage({ type: 'REFRESH_SETTINGS' }, '*');
        } catch (error) {
            console.error('Failed to update settings:', error);
        }
    }

    const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAvatar(event.target.files ? event.target.files[0] : null);
    };

    const handleAvatarUpload = async () => {
        console.log('handleAvatarUpload');
        if (!avatar) {
            return;
        }

        console.log('handleAvatarUpload 2');

        const canvas = editor.getImageScaledToCanvas();
        canvas.toBlob(async (blob) => {
            console.log('handleAvatarUpload 3');
            if (!blob) {
                return;
            }

            console.log('handleAvatarUpload 4');

            const formData = new FormData();
            formData.append('avatar', blob);

            try {
                const response = await fetchService(`${config.API_ENDPOINT}/upload/avatar`, 'POST', formData);
                dispatch(updateSettings({ orgId, settings: {avatarUrl: response.avatarUrl }}));
                setAvatar(null);
                setIsHovered(false);
            } catch (error) {
                console.error(error);
            }
        }, 'image/jpeg', 0.9);


    };

    if (!settings.initialized) {
        return null;
    }

    return (
        <div className="container mx-auto flex space-x-4">
            <div className="w-1/2 p-4 bg-white">
                <TitleBar name="Appearance"/>
                <div className="p-4 mb-8 border border-gray-200 rounded">
                    <h2 className="text-xl mb-2 text-orange-600">Brand Colors</h2>
                    <div className="mb-8">
                        <h3 className="text-sm mb-2">Widget Color</h3>
                        <ColorPicker color={settings.color} onChange={notifyIframe}/>
                    </div>
                    <div className="mb-8">
                        <h3 className="text-sm mb-2">Gradient</h3>
                        <GradientSlider gradient={settings.gradient} onChange={notifyIframe}/>
                    </div>
                </div>

                <div className="p-4 mb-8 border border-gray-200 rounded">
                    <h2 className="text-xl mb-2 text-orange-600">Chatbot Avatar</h2>
                    {settings.avatarUrl ? (
                        <div className="relative mt-4"
                             style={{ width: '100px', height: '100px' }}
                             onMouseEnter={() => setIsHovered(true)}
                             onMouseLeave={() => setIsHovered(false)}>
                            <img src={settings.avatarUrl} alt="Avatar" className="rounded-full w-full"/>
                            {isHovered && (
                                <button
                                    className="absolute inset-0 flex items-center justify-center bg-white opacity-50 text-red-500 font-bold rounded-full cursor-pointer"
                                    onClick={() => dispatch(updateSettings({orgId, settings: {avatarUrl: ''}}))}
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className="mt-4">
                            <Logo size={96} hoverBounce={false}/>
                        </div>
                    )}
                    {!settings.avatarUrl && (
                        <div className="mb-8">
                            <div className="relative inline-block">
                                <input
                                    type="file"
                                    onChange={handleAvatarChange}
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        opacity: 0,
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                                {!avatar && <button className="py-2 px-4 bg-blue-500 text-white">Upload avatar</button>}
                            </div>
                            {avatar && (
                                <>
                                    <AvatarEditor
                                        ref={setEditorRef}
                                        image={URL.createObjectURL(avatar)}
                                        width={250}
                                        height={250}
                                        border={50}
                                        color={[255, 255, 255, 0.6]} // RGBA
                                        scale={1.2}
                                        borderRadius={1000}
                                    />
                                    <div className="mt-4">
                                        <button
                                            onClick={handleAvatarUpload}
                                            className="py-2 px-4 bg-blue-500 text-white rounded mr-2"
                                        >Save</button>
                                        <button
                                            onClick={() => setAvatar(null)}
                                            className="py-2 px-4 bg-gray-300 text-black rounded"
                                        >Cancel</button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>

                <div className="p-4 mb-8 border border-gray-200 rounded">
                    <h2 className="text-xl mb-2 text-orange-600">Launching your Chat</h2>
                    <div className="mb-8">
                        <h3 className="text-sm mb-2">Chat Button</h3>
                        <LaunchBtnPicker iconId={settings.iconId} color={settings.color} onChange={notifyIframe}/>

                        {/* Use PopupToggle component */}
                        <PopupToggle showPopup={settings.showPopup} onChange={notifyIframe}/>
                    </div>
                    {/* other settings can be added here */}
                </div>

                <div className="p-4 mb-8 border border-gray-200 rounded">
                    <h2 className="text-xl mb-2 text-orange-600">Chat Voice</h2>
                    <div className="mb-8">
                        <h3 className="text-sm mb-2">Welcome Message</h3>
                        <WelcomeEditor welcomeMessage={settings.welcome} onChange={reloadIframe}/>
                    </div>
                    {/* other sections can be added here */}
                </div>
            </div>

            <div className="w-1/2 p-4 bg-gray-100 h-screen">
                <h2 className="text-2xl font-bold mb-4 text-gray-200">Preview</h2>
                <button onClick={toggleChatbot} className="absolute bottom-4 right-4">
                    {settings.iconId === 'bot_1' && <Bot1Icon className="h-10 w-10" fill={settings.color}/>}
                    {settings.iconId === 'person_1' && <Person1Icon className="h-10 w-10" fill={settings.color}/>}
                    {settings.iconId === 'question_1' && <Question1Icon className="h-10 w-10" fill={settings.color}/>}
                    {settings.iconId === 'info_1' && <Info1Icon className="h-10 w-10" fill={settings.color}/>}
                    {settings.iconId === 'chat_1' && <Chat1Icon className="h-10 w-10" fill={settings.color}/>}
                </button>

                <div className="chatbot-preview-container">
                {showChatbot ? <ChatIframe iframeKey={iframeKey} orgId={orgId}/> : null}
                </div>
            </div>
        </div>
    );
};

export default React.memo(Appearance);