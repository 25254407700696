import { useAppSelector } from '../store/hooks.js';

// This is a custom hook that takes an action name and returns a function that checks whether the current user's subscription allows that action.
export const useCheckFAQSubscription = () => {
    const { subscriptions } = useAppSelector(state => state.subscriptions);
    const { faqs } = useAppSelector(state => state.faq);


    // Implement the logic for checking whether the regenerateAnswers action is allowed.
    // This is just a placeholder. Replace it with your actual logic.
    const lastRegenerationTime = faqs[0]?.generatedAnswer?.time; // Assuming the FAQs are sorted by date
    if (!lastRegenerationTime) return true;

    let isActionAllowed;
    const lastRegenerationDate = new Date(String(lastRegenerationTime));
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const oneDayAgo = new Date();
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);

    if (!subscriptions && lastRegenerationDate < oneWeekAgo) {
        isActionAllowed = true;
    } else {
        isActionAllowed = false;
    }

    // Additional logic specific to FAQs...

    return isActionAllowed;
};

// This is a custom hook for Leads that returns a function that checks whether the current user's subscription allows a certain action.
export const useCheckLeadsSubscription = (action) => {
    const { subscriptions } = useAppSelector(state => state.subscriptions);
    const { leads } = useAppSelector(state => state.leads);

    // Implement the logic for checking whether the action is allowed.
    // This is just a placeholder. Replace it with your actual logic.
    const isActionAllowed = subscription.plan.features.includes(action);

    // Additional logic specific to Leads...

    return isActionAllowed;
};