import React, {useState, FormEvent, useContext} from 'react';
import Header from "../components/Header";
import {Link, useNavigate} from "react-router-dom";
import {ConfigContext} from "../ConfigContext";
import {useAuth} from "../AuthContext";

// Replace with your authentication logic
const authenticateUser = async (email: string, password: string, config: any) => {
    // Simulate an API call

    try {
        const response = await fetch(`${config.API_ENDPOINT}/account/login`, {
            integrtring: undefined,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password })
        });

        if (response.status === 200) {
            console.log('User logged in successfully');
            const data = await response.json();
            return data;
        } else {
            throw new Error('Failed to login');
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
    return email === 'user@example.com' && password === 'password';
};

const Login: React.FC = () => {
    const config = useContext(ConfigContext);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setError(null);

        // Perform authentication logic here (e.g., call an API)
        try {
            // Replace with your authentication logic
            const data = await authenticateUser(email, password, config);

            if (data && data.access_token) { // <-- Check if token exists in the response
                login({access_token:data.access_token, refresh_token:data.refresh_token});
                navigate('/builder'); // <-- Route to the dashboard
            } else {
                setError('Invalid email or password');
            }

        } catch (err) {
            setError('An error occurred. Please try again.');
        }
    };
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <Header
                    heading="Sign in to your account"
                />

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="text-sm">
                                    <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                        Forgot password?
                                    </a>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} // Here's where setPassword is used
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Sign in
                            </button>
                        </div>
                    </form>
                    {error && <p className="text-red-500 text-center mt-2">{error}</p>}

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?{' '}
                        <Link to="/signup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            Start a 14 day free trial
                        </Link>
                    </p>
                </div>
            </div>
        </>
    )
};

export default Login;
