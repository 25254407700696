import React, {useEffect, useRef, useState} from 'react';
import TitleBar from "../components/TitleBar";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Loader from "../components/OrbiLoader";
import { fetchSummary, generateSummary, updateSummary } from "../store/summarySlice";

const Summary = () => {
    const dispatch = useAppDispatch();
    const { summary, loadingFetch, loadingGenerate, error } = useAppSelector(state => state.summary);
    const isFetching = useRef(false);

    useEffect(() => {
        if (!loadingFetch && !isFetching.current) {
            isFetching.current = true;
            dispatch(fetchSummary())
                .finally(() => {
                    isFetching.current = false;
                });
        }
    }, [dispatch, loadingFetch]);

    const handleGenerateSummary = () => {
        dispatch(generateSummary());
    }

    const handleEditSummary = () => {
        // Implement editing logic
    };

    const handleSave = () => {
        // Implement save logic
    };

    return (
        <div className="container mx-auto p-8 bg-white max-w-7xl">
            <TitleBar name='Summary'/>
            {loadingFetch && <Loader/>}
            {loadingGenerate && <div>Generating Summary...</div>}
            {error ? (
                <div>Error</div>
                ) :
                (summary ? (
                    <div className="border-gray-200 py-2 relative">
                        <div className="p-4 border rounded-lg">
                            <div className="mt-2">
                                {summary.customSummary ?
                                    <p>{summary.customSummary.content}</p> :
                                    <p>{summary.generatedSummary.content}</p>
                                }
                            </div>
                        </div>
                        <button onClick={() => handleEditSummary()}>
                            Edit
                        </button>
                    </div>
                ) : (
                        <div className="mt-8 text-center border p-4 rounded">
                            <h2 className="text-3xl font-bold leading-tight">Generate a Summary of your content</h2>
                            <p className="mt-4 text-lg leading-relaxed text-gray-700">
                                Generate a summary based on your website content.  This can give you an immediate insight
                            into how well your AI assistant has understood your website content.</p>
                            <div className="flex justify-center items-center my-10"> {/* Adjust height as needed */}
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-10 rounded-md transition-all duration-300 ease-in-out shadow-lg"
                                    onClick={handleGenerateSummary}
                                >
                                    Generate Summary based on your website content
                                </button>
                            </div>
                        </div>
                    )
                )}
        </div>
    );
};

export default Summary;
