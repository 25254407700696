import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchService } from "../Widget/utils";
import Summary from "../pages/Summary";
import config from "../config";

interface ContentSchema {
    content: string,
    time: Date
};



interface SummarySchema {
    _id: string;
    orgId: string;
    generatedSummary: ContentSchema;
    customSummary: ContentSchema;
}

interface SummaryState {
    summary: SummarySchema;
    loadingFetch: boolean;
    loadingGenerate: boolean;
    error: string | null;
}

const initialState: SummaryState = {
    summary: null,
    loadingFetch: false,
    loadingGenerate: false,
    error: null,
};

// Async thunk for generating FAQs
export const generateSummary = createAsyncThunk(
    'summary/generateSummary',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/summary/generate`, 'POST');
            return response as SummarySchema;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk for fetching FAQs
export const fetchSummary = createAsyncThunk(
    'summary/fetchSummary',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/summary`, 'GET');
            return response as SummarySchema;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);


export const updateSummary = createAsyncThunk(
    'summary/updateSummary',
    async (summary: SummarySchema, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/summary/update`, 'PUT', summary);
            return response as SummarySchema;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const regenerateSummary = createAsyncThunk(
    'summary/regenerateSummary',
    async (summary: SummarySchema, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/summary/regenerate`, 'POST', summary);
            return response as SummarySchema;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);



export const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(generateSummary.pending, (state) => {
                state.loadingGenerate = true;
                state.error = null;
            })
            .addCase(generateSummary.fulfilled, (state, action) => {
                state.loadingGenerate = false;
                state.summary.generatedSummary = action.payload;
            })
            .addCase(generateSummary.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.error = action.payload as string;
            })
            .addCase(fetchSummary.pending, (state) => {
                state.loadingFetch = true;
                state.error = null;
            })
            .addCase(fetchSummary.fulfilled, (state, action) => {
                state.loadingFetch = false;
                state.summary = action.payload;
            })
            .addCase(fetchSummary.rejected, (state, action) => {
                state.loadingFetch = false;
                state.error = action.payload as string;
            })
            .addCase(updateSummary.pending, (state) => {
                state.loadingGenerate = true;
                state.error = null;
            })
            .addCase(updateSummary.fulfilled, (state, action) => {
                state.loadingGenerate = false;
                state.summary = action.payload;
            })
            .addCase(updateSummary.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.error = action.payload as string;
            })
            .addCase(regenerateSummary.pending, (state) => {
                state.loadingGenerate = true;
                state.error = null;
            })
            .addCase(regenerateSummary.fulfilled, (state, action) => {
                state.loadingGenerate = false;
                state.summary = action.payload;
            })
            .addCase(regenerateSummary.rejected, (state, action) => {
                state.loadingGenerate = false;
                state.error = action.payload as string;
            })
    }
});

export default summarySlice.reducer;
