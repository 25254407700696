import React from 'react';
import ReactDOM from 'react-dom';

interface PopupProps {
    show: boolean;
    onClose: () => void;
    style?: React.CSSProperties;
    children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({ show, onClose, style, children }) => {
    if (!show) return null;

    const content = (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div style={style} className="bg-white p-8 rounded-lg relative">
                <button onClick={onClose} className="absolute top-2 right-2">
                    ❌
                </button>
                {children}
            </div>
        </div>
    );

    // Assuming you have a div with id "portal" in your index.html
    return ReactDOM.createPortal(content, document.getElementById('portal')!);
};

export default Popup;
