import React, {createContext, useState, useContext, useEffect} from 'react';
const config = import('./config.js');

interface ConfigContextProps {
  apiUrl: string;
}

export const ConfigContext = createContext<ConfigContextProps | undefined>(undefined);

interface ConfigProviderProps {
  children: React.ReactNode;
}



export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [loadedConfig, setLoadedConfig] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      const importedConfig = await config;
      setLoadedConfig(importedConfig.default);
    };

    loadConfig().then();
  }, []);

  if (!loadedConfig) return null; // or a loading spinner

  return (
    <ConfigContext.Provider value={loadedConfig}>
      {children}
    </ConfigContext.Provider>
  );
};

