import React, {useEffect, useRef, useState} from 'react';
import Popup from "../components/Popup";
import TitleBar from "../components/TitleBar";
import {fetchService} from "../Widget/utils";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {
    deleteWebpage,
    fetchWebpages,
    updateStatusFromServer,
    updateWebpages,
    updateWebpageStatus
} from "../store/trainingDataSlice";
import {WebpageStatus} from "../enums";
import config from "../config";


interface TrainingProps {
}

const TrainingData: React.FC<TrainingProps> = () => {
    const dispatch = useAppDispatch();
    const {webpages, loading, error} = useAppSelector(state => state.trainingData);
    const [showPopup, setShowPopup] = useState(false);
    const [enteredURL, setEnteredURL] = useState('');
    const [selectedLinks, setSelectedLinks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [discoveredLinks, setDiscoveredLinks] = useState<{ domainLinks: string[], otherLinks: string[]}>({ domainLinks: [], otherLinks: []});
    const [eventToken, setEventToken] = useState('');
    const maxTrainingMaterials = 20;
    const maxWordCount = 100000;
    const completedWebpagesCount = webpages.filter(page => page.status === 'completed').length;
    const totalWordCount = webpages.reduce((acc, page) => {
        return page.status === 'completed' ? acc + (page.wordCount || 0) : acc;
    }, 0);
    const canTrainMore = completedWebpagesCount < maxTrainingMaterials && totalWordCount < maxWordCount;
    const [addedLinks, setAddedLinks] = useState(new Set());
    const [isLinksPopup, setIsLinksPopup] = useState(false);
    const [selectAllDomainLinks, setSelectAllDomainLinks] = useState(false);
    const [selectAllOtherLinks, setSelectAllOtherLinks] = useState(false);


    const hasActiveWebpages = webpages.some(
        (page) => page.status === 'queued' || page.status === 'training'
    );

    // Function to fetch event token
    const fetchEventToken = async () => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/sse/token`, 'GET');
            console.log('Event token response:', response);
            setEventToken(response.event_token);
            return response.event_token;
            // return setupEventSource(response.event_token);
        } catch (error) {
            console.error('Error fetching event token:', error);
        }
    };

    const setupEventSource = (token) => {
        console.log('Setting up event source', token);
        const url = new URL(`${config.API_ENDPOINT}/sse/trainingdata/status`);
        url.searchParams.append('eventToken', token);

        const eventSource = new EventSource(url.toString());

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            dispatch(updateStatusFromServer(data)); // Action from your slice
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
            eventSource.close();
        };

        return eventSource;
    };


    const handleSelectAllDomainLinks = (checked) => {
        setSelectAllDomainLinks(checked);
        if (checked) {
            setSelectedLinks(prevLinks => Array.from(new Set([...prevLinks, ...discoveredLinks.domainLinks])));
        } else {
            setSelectedLinks(prevLinks => prevLinks.filter(link => !discoveredLinks.domainLinks.includes(link)));
        }
    };

    // Function to handle select all for other links
    const handleSelectAllOtherLinks = (checked) => {
        setSelectAllOtherLinks(checked);
        if (checked) {
            setSelectedLinks(prevLinks => Array.from(new Set([...prevLinks, ...discoveredLinks.otherLinks])));
        } else {
            setSelectedLinks(prevLinks => prevLinks.filter(link => !discoveredLinks.otherLinks.includes(link)));
        }
    };



    const discoverLinks = async () => {
        setIsLoading(true);

        const links = await fetchService(`${config.API_ENDPOINT}/scrape/links`, 'POST', { url: enteredURL });
        setIsLoading(false);
        setDiscoveredLinks(links);
    };

    const handleSelectLink = (url) => {
        if (selectedLinks.includes(url)) {
            // Deselect it
            setSelectedLinks(selectedLinks.filter((selectedUrl) => selectedUrl !== url));
        } else {
            // Select it
            setSelectedLinks([...selectedLinks, url]);
        }
    };


    const selectAllLinks = () => {
        // Combine all URLs into a single array
        const allURLs = [...discoveredLinks.domainLinks, ...discoveredLinks.otherLinks];
        setSelectedLinks(allURLs);
    };

    const deselectAllLinks = () => {
        setSelectedLinks([]);
    };

    const handleAddWebpage = () => {
        const newURL = prompt('Enter the new URL:');
        if (newURL) {
            // setWebpages([...webpages, { url: newURL, status: WebpageStatus.Queued, type: '', size: '', dateAdded: '', lastTrained: 'N/A' }]);
        }
    };

    const handleRefreshURL = (index: number) => {
        // Logic to refresh the URL goes here
        console.log(`Refreshing URL at index ${index}`);
    };

    const handleDeleteWebpage = async (id: string) => {
        console.log('handleDeleteWebpage', id);
        try {
            await dispatch(deleteWebpage(id)).unwrap();
        } catch (error) {
            console.error('An error occurred while deleting the webpage:', error);
        }
    };

    const handleOpenPopup = (webpage?) => {
        const isLinksPopup = Boolean(webpage);
        const newAddedLinks = new Set(webpages.map(wp => wp.url));
        setIsLinksPopup(isLinksPopup);
        setAddedLinks(newAddedLinks);
        setSelectAllDomainLinks(false);
        setSelectAllOtherLinks(false)

        if (isLinksPopup) {
            setDiscoveredLinks({
                domainLinks: webpage.domainLinks || [],
                otherLinks: webpage.otherLinks || []
            });
        } else {
            setDiscoveredLinks({domainLinks:[], otherLinks:[]});
        }
        setShowPopup(true);
    };


    // Function to handle retraining of a webpage
    const handleRetrainWebpage = async (id: string) => {
        try {
            // API call to re-queue the webpage
            await fetchService(`${config.API_ENDPOINT}/scrape/requeue/${id}`, 'POST');
            dispatch(updateWebpageStatus({ id, status: WebpageStatus.Queued }));
        } catch (error) {
            console.error('Error retraining webpage:', error);
        }
    };

    // This function is called when the user initiates the queuing process
    const queueForGatheringAssets = async () => {
        try {
            const responseData = await fetchService(`${config.API_ENDPOINT}/scrape/queue`, 'POST', { links: selectedLinks });
                const newURLItems = responseData.data; // The new URL items from the server
                dispatch(updateWebpages(newURLItems));
                setSelectedLinks([]); // Reset selected URLs after successful queuing
                setShowPopup(false);

        } catch (error) {
            console.error('An error occurred while queuing the links:', error);
        }
    };


    useEffect(() => {
        let eventSource;
        if (hasActiveWebpages) {
            fetchEventToken()
                .then((token) => {
                    eventSource = setupEventSource(token);
                });
        } else if (eventSource){
            console.log('eventSource closed')
            eventSource.close();
        }

        return () => {
            if (eventSource) {
                console.log('eventSource closed')
                eventSource.close();
            }
        };
    }, [dispatch, hasActiveWebpages]);

    const isFetching = useRef(false);

    useEffect(() => {
        if (webpages.length === 0 && !loading && !isFetching.current) {
            console.log('Fetching webpages');
            isFetching.current = true;
            dispatch(fetchWebpages())
                .finally(() => {
                    isFetching.current = false;
                });
        }
    }, [dispatch, webpages.length, loading]);


    return (
            <div className="container mx-auto p-8 bg-gray-100 max-w-7xl">
                <TitleBar />

                <div className="rounded-lg border border-gray-200 mt-8 p-4">
                    <div className="mb-4 flex justify-between items-center">
                        <button type="button" className="bg-blue-500 text-white px-4 py-2 font-semibold" onClick={() => handleOpenPopup()}>
                            Add Training Data
                        </button>
                    </div>
                </div>

                <div className="rounded-lg border border-gray-200 mt-8 justify-between p-4 overflow-x-auto">
                        <div className="flex space-x-4 items-center justify-between">
                            <h2 className="text-lg font-bold mb-4 opacity-100 transform translate-x-0 translate-z-0">Step 2: Train</h2>
                            <p className="mr-10 mb-4">{completedWebpagesCount}/{maxTrainingMaterials} Training Materials, {totalWordCount}/{maxWordCount} Words</p>
                        </div>
                        <table className="min-w-full bg-white">
                            <thead>
                            <tr>
                                <th className="border-b border-gray-200 py-2">URL</th>
                                <th className="border-b border-gray-200 py-2">Status</th>
                                <th className="border-b border-gray-200 py-2">Type</th>
                                <th className="border-b border-gray-200 py-2">Word Count</th>
                                <th className="border-b border-gray-200 py-2">Date Added</th>
                                <th className="border-b border-gray-200 py-2">Last Trained</th>
                                <th className="border-b border-gray-200 py-2">Links</th>
                                <th className="border-b border-gray-200 py-2">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {webpages.map((item, index) => {

                                const unscrapedDomainLinksCount = item.domainLinks.filter(link => !webpages.some(wp => wp.url === link)).length;
                                return (
                                    <tr key={index}>
                                        <td className="border-b border-gray-200 py-2 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap"> {/* Set max-width and text truncation */}
                                            <a href={item.url} target="_blank" rel="noopener noreferrer" title={item.url} className="hover:underline">
                                                {item.url}
                                            </a>
                                        </td>
                                        <td className="border-b border-gray-200 py-2">{item.status}</td>
                                        <td className="border-b border-gray-200 py-2">{item.type}</td>
                                        <td className="border-b border-gray-200 py-2">{item.wordCount}</td>
                                        <td className="border-b border-gray-200 py-2">{new Date(item.addedDate).toLocaleDateString()}</td>
                                        <td className="border-b border-gray-200 py-2">{new Date(item.modifiedDate).toLocaleDateString()}</td>
                                        <td className="border-b border-gray-200 py-2">
                                            <button
                                                onClick={() => handleOpenPopup(item)}
                                                className={`px-4 py-2 font-semibold rounded ${unscrapedDomainLinksCount > 0 ? 'bg-blue-500 text-white' : 'bg-gray-500 text-gray-200'}`}
                                            >
                                                {unscrapedDomainLinksCount}
                                            </button>
                                        </td>
                                        <td className="border-b border-gray-200 py-2">
                                            <button onClick={() => handleRetrainWebpage(item._id)} className="mr-2">
                                                🔄
                                            </button>
                                            <button onClick={() => handleDeleteWebpage(item._id)}>
                                                ❌
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>

                <Popup show={showPopup} onClose={() => {setShowPopup(false); setIsLinksPopup(false);}} style={{ width: '800px', height: '800px' }}>
                    <div className="bg-white p-5 shadow-md">

                        {!isLinksPopup && (
                            <div>
                                <h2 className="text-lg font-bold mb-4">Add New URL</h2>

                                <div className="flex mb-5">
                                    <input
                                        className="border rounded p-2 w-full mr-4"
                                        type="text"
                                        placeholder="Enter or paste the website URL here"
                                        value={enteredURL}
                                        onChange={e => setEnteredURL(e.target.value)}
                                    />
                                    <button className="bg-green-500 text-white py-2 px-4 rounded" type="button" onClick={discoverLinks}>
                                        Fetch URL
                                    </button>
                                </div>
                            </div>
                        )}


                        {isLoading && <p>Loading...</p>}

                        <div className="mb-5">
                            <div className="flex justify-between mb-2">
                                <span className="font-medium">We found some links on this page you might want to add:</span>
                            </div>

                            <div className="overflow-y-auto" style={{ maxHeight: '500px' }}>
                                {/* Group for domainLinks */}
                                <div>
                                    <h3 className="font-bold text-lg mb-3">Domain Links:</h3>
                                    <div className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            checked={selectAllDomainLinks}
                                            onChange={(e) => handleSelectAllDomainLinks(e.target.checked)}
                                        />
                                        <span>Select All</span>
                                    </div>
                                    {discoveredLinks.domainLinks.map((url) => (
                                        <div className={`flex items-center mb-2 ${addedLinks.has(url) ? "text-gray-500 opacity-50" : "text-blue-500"}`} key={url}>
                                            <input
                                                className="border mr-2"
                                                type="checkbox"
                                                checked={selectedLinks.includes(url) || addedLinks.has(url)}
                                                style={addedLinks.has(url) ? { filter: 'grayscale(100%)', opacity: 0.5 } : {}}
                                                onClick={() => handleSelectLink(url)}
                                                disabled={addedLinks.has(url)}
                                            />
                                            <a href={url} className="hover:underline" target="_blank" rel="noopener noreferrer">{url}</a>
                                        </div>
                                    ))}
                                </div>

                                {/* Group for otherLinks */}
                                <div>
                                    <h3 className="font-bold text-lg mb-3">Other Links:</h3>
                                    <div className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            checked={selectAllOtherLinks}
                                            onChange={(e) => handleSelectAllOtherLinks(e.target.checked)}
                                        />
                                        <span>Select All</span>
                                    </div>
                                    {discoveredLinks.otherLinks.map((url) => (
                                        <div className={`flex items-center mb-2 ${addedLinks.has(url) ? "text-gray-500 opacity-50" : "text-blue-500"}`} key={url}>
                                            <input
                                                className="border mr-2"
                                                type="checkbox"
                                                checked={selectedLinks.includes(url)}
                                                style={addedLinks.has(url) ? { filter: 'grayscale(100%)', opacity: 0.5 } : {}}
                                                onClick={() => handleSelectLink(url)}
                                                disabled={addedLinks.has(url)}/>
                                            <a href={url} className="hover:underline" target="_blank" rel="noopener noreferrer">{url}</a>
                                        </div>
                                    ))}
                                </div>
                            </div>



                        </div>

                        {/* OK/Done button */}
                        <div className="mt-4 flex justify-end">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                type="button"
                                onClick={queueForGatheringAssets}
                            >
                                OK / Done
                            </button>
                        </div>
                    </div>
                </Popup>


            </div>

    );
};

export default TrainingData;
