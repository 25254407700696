import React from 'react';
import {updateSettings} from "../store/settingsSlice";
import {useAppDispatch} from "../store/hooks";
import {useAuth} from "../AuthContext";

interface WelcomePopupProps {
    showPopup: boolean;
    onChange: any
}

const PopupToggle: React.FC<WelcomePopupProps> = ({showPopup, onChange}) => {

    const dispatch = useAppDispatch();
    const { orgId } = useAuth();

    const handleChange = async () => {
        await dispatch(updateSettings({ orgId, settings: { showPopup: !showPopup } }));
        onChange();
    };
    return (
        <div className="mt-4">
            <label className="flex items-center">
                <input type="checkbox"
                       checked={showPopup}
                       onChange={handleChange}
                       className="form-checkbox h-5 w-5 text-orange-600"/>
                <span className="ml-2 text-gray-700">Show Welcome Popup</span>
            </label>
        </div>
    );
};

export default PopupToggle;