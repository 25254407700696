import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchService } from "../Widget/utils";
import config from "../config";

interface Subscription {
    // Define your Subscription model here
}

interface SubscriptionState {
    subscriptions: Subscription[];
    loading: boolean;
    error: string | null;
}

const initialState: SubscriptionState = {
    subscriptions: null,
    loading: false,
    error: null,
};

export const fetchSubscriptions = createAsyncThunk(
    'subscriptions/fetchSubscriptions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/subscriptions`, 'GET');
            return response as Subscription[];
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubscriptions.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptions = action.payload;
            })
            .addCase(fetchSubscriptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});

export default subscriptionsSlice.reducer;