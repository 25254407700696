import React from "react";

interface LogoProps {
    size?: number;
    hoverBounce?: boolean;
}

const Logo: React.FC<LogoProps> = ({size = 48, hoverBounce = true}) => {
    return (
        <svg
            style={{width: `${size}px`, height: `${size}px`}}
            className={`transform transition-transform duration-300 ${hoverBounce ? 'hover:scale-110 hover:-translate-y-1' : ''}`}
            id="ex2NvBV39EI1"
            viewBox="0 0 300 300"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
        >
            <path d="M 150,50 A 100,100 0 0,1 150,250 A 100,100 0 0,1 150,50 M 150,110 A 40,40 0 0,0 150,190 A 40,40 0 0,0 150,110" fill="#f4a400" />
            <ellipse cx="70" cy="70" rx="25" ry="25" fill="#f4a400" />
            <ellipse cx="230" cy="70" rx="25" ry="25" fill="#f4a400" />
        </svg>
    );
}

export default Logo;
