// WelcomeMessageComponent.tsx

import React, { useState } from "react";
import {useAppDispatch} from "../store/hooks";
import {useAuth} from "../AuthContext";
import {updateSettings} from "../store/settingsSlice";

type Props = {
    welcomeMessage: string;
   onChange: Function;
};

export const WelcomeEditor: React.FC<Props> = ({welcomeMessage,onChange}) => {

    const dispatch = useAppDispatch();
    const { orgId } = useAuth();

    const [welcome, setWelcome] = useState(welcomeMessage);

    const handleWelcomeMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setWelcome(event.target.value);
    };

    const handleWelcomeMessageBlur = async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        await dispatch(updateSettings({ orgId: orgId, settings: {welcome: event.target.value }}));
        onChange();
    };

    return (
        <textarea
            rows={4}
            style={{height: '200px', overflowY: 'scroll'}}
            value={welcome}
            onChange={handleWelcomeMessageChange}
            onBlur={handleWelcomeMessageBlur}
            placeholder="Enter welcome message"
            className="border rounded-md p-2 w-full" // Add your CSS classes here
        />
    );
};