import React, { useState, useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import { updateSettings } from "../store/settingsSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {useAuth} from "../AuthContext";

const ColorPicker = ({ color, onChange }) => {
    const dispatch = useAppDispatch();
    const { orgId } = useAuth();


    const handleColorChange = async (color) => {
        console.log('handleColorChange', orgId, color.hex);
        await dispatch(updateSettings({orgId, settings: { color: color.hex }}));
        onChange(); // Call the callback function with the new color
    };

    return <TwitterPicker color={color} onChangeComplete={handleColorChange} />;
};

export default ColorPicker;