import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchService } from '../Widget/utils';
import config from "../config";

export enum Status {
    Idle = 'idle',
    Pending = 'pending',
    Fulfilled = 'fulfilled',
    Rejected = 'rejected',

}

interface SettingsState {
    color?: string;
    status: Status;
    initialized: boolean;
    error?: string | null;
    welcome: string;
    showPopup: boolean;
    companyName: string;
    botName: string;
    iconId: string;
    gradient: -1 | 0 | 1;
    avatarUrl: string;
}

const initialState: SettingsState = {
    color: null,
    status: Status.Idle,
    initialized: false,
    error: null,
    welcome: null,
    showPopup: false,
    companyName: null,
    iconId: null,
    botName: null,
    gradient: 0,
    avatarUrl: null
};


// Async thunk for fetching settings
export const fetchSettings = createAsyncThunk(
    'settings/fetchSettings',
    async (orgId: string, {rejectWithValue} ) => {
    // async ({orgId}{orgId}, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/settings/${orgId}`, 'GET');
            console.log('fetchSettings response', response)
            return response as SettingsState;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk for updating settings
export const updateSettings = createAsyncThunk(
    'settings/updateSettings',
    async ({orgId, settings}: { orgId: string, settings: Partial<SettingsState>}, { rejectWithValue }) => {
        try {
            console.log('updateSettings', orgId, settings);
            const response = await fetchService(`${config.API_ENDPOINT}/settings/${orgId}`, 'PUT', settings);
            console.log('updateSettings', response);
            return response as SettingsState;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSettings.pending, (state) => {
                state.status = Status.Pending;
                state.error = null;
            })
            .addCase(fetchSettings.fulfilled, (state, action) => {
                state.status = Status.Fulfilled;
                state.initialized = true;
                Object.entries(action.payload).forEach(([key, value]) => {
                    state[key] = value;
                });
            })
            .addCase(fetchSettings.rejected, (state, action) => {
                state.status = Status.Rejected;
                state.error = action.payload as string;
            })
            .addCase(updateSettings.pending, (state) => {
                state.status = Status.Pending;
                state.error = null;
            })
            .addCase(updateSettings.fulfilled, (state, action) => {
                state.status = Status.Fulfilled;
                Object.entries(action.payload).forEach(([key, value]) => {
                    state[key] = value;
                });
            })
            .addCase(updateSettings.rejected, (state, action) => {
                state.status = Status.Rejected;
                state.error = action.payload as string;
            });
    },
});

export default settingsSlice.reducer;