import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IChatLog} from '../types'; // Import your chat type
import { fetchService } from '../Widget/utils';
import config from "../config"; // Import your fetch service

interface ChatLogState {
    chats: IChatLog[];
    selectedChatId: string | null;
    chatDetails: IChatLog;
    loading: boolean;
    error: string | null;
}

const initialState: ChatLogState = {
    chats: [],
    selectedChatId: null,
    chatDetails: null,
    loading: false,
    error: null,
};

export const chatLogSlice = createSlice({
    name: 'chatLog',
    initialState,
    reducers: {
        selectChat: (state, action: PayloadAction<string>) => {
            state.selectedChatId = state.chats.find(chat => chat._id === action.payload)._id || null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChatLogs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchChatLogs.fulfilled, (state, action) => {
                state.loading = false;
                state.chats = action.payload;
            })
            .addCase(fetchChatLogs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchChatDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchChatDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.chatDetails = action.payload;
            })
            .addCase(fetchChatDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default chatLogSlice.reducer;

export const { selectChat } = chatLogSlice.actions;

export const fetchChatLogs = createAsyncThunk(
    'chatLog/fetchChatLogs',
    async (_, { rejectWithValue }) => {
        try {
            const chats = await fetchService(`${config.API_ENDPOINT}/chatlog/logs`, 'GET');
            return chats as IChatLog[];
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchChatDetails = createAsyncThunk(
    'chat/fetchChatDetails',
    async (chatId: string, { rejectWithValue }) => {
        try {
            const response = await fetchService(`${config.API_ENDPOINT}/chatlog/chat/${chatId}`, 'GET');
            // Ensure the response is of type IChatDetail
            return response as IChatLog;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);