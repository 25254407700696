import {RequestMethod, WidgetSettings} from "./types";
import {DEFAULT_BASE_URL} from "./constants";
import config from "../config";



export function noop() {}

export const isValidUuid = (id: any) => {
    if (!id || !id.length) {
        return false;
    }

    const regex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    return regex.test(id);
};

export const fetchWidgetSettings = async (
    query: {account_id: string; inbox_id?: string},
    baseUrl = DEFAULT_BASE_URL
): Promise<WidgetSettings> => {
    return {} as WidgetSettings;
};

export const updateWidgetSettingsMetadata = async (
    params: {
        account_id: string;
        inbox_id?: string;
        metadata: any;
    },
    baseUrl = DEFAULT_BASE_URL
): Promise<WidgetSettings> => {
    return {} as WidgetSettings;
};


export async function fetchService(url: string, method: RequestMethod, data?: any): Promise<any> {
    let tokens: {access_token:string, refresh_token} | null = getTokens(); // abstracted away storage
    console.log('fetchService', url, tokens, method, data);
    const headers: HeadersInit = {};

    if (tokens && tokens.access_token) {
        headers['Authorization'] = `Bearer ${tokens.access_token}`;
    }

    if (!(data instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
    }

    console.log('tokens', tokens);

    const requestOptions: RequestInit = {
        method,
        headers,
    };

    if (data) {
        requestOptions.body = data instanceof FormData ? data : JSON.stringify(data);
    }

    try {
        // TODO: need ti figure out why we are not getting a new access token
        const response = await fetch(url, requestOptions);
        console.log('response', response);

        // Handle token expiration
        if (response.status === 401 && tokens && tokens.refresh_token) {
            console.log('Token expired, attempting to refresh');
            const newTokens = await refreshToken(tokens.refresh_token);
            if (newTokens && newTokens.access_token) {
                headers['Authorization'] = `Bearer ${newTokens.access_token}`;
                const retryResponse = await fetch(url, requestOptions);
                if (!retryResponse.ok) {
                    throw new Error('Network response was not ok after token refresh.');
                }
                return retryResponse.json();
            } else {
                // If unable to get a new access token, redirect to login or handle accordingly
                redirectToLogin();
            }
        }

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return response.json();
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
        throw error;
    }
}

export function getTokens(): {access_token: string, refresh_token: string} | null {
    return JSON.parse(localStorage.getItem('tokens') || '{}');
}

function setTokens(tokens: {access_token: string, refresh_token: string}): void {
    localStorage.setItem('tokens', JSON.stringify(tokens));
}

async function refreshToken(refreshToken: string): Promise<{ access_token: string, refresh_token: string } | null> {
    // Make a request to your backend to refresh the token
    const response = await fetch(`${config.API_ENDPOINT}/tokens/refresh`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh_token: refreshToken })
    });

    console.log('refresh response', response);

    if (!response.ok) {
        return null;
    }

    const newTokens = await response.json();
    setTokens(newTokens); // Update local storage
    return newTokens;
}


function redirectToLogin() {
    // Handle the redirection to your login page
    window.location.href = "/login";
}


