import { configureStore } from '@reduxjs/toolkit';
import trainingDataReducer from './trainingDataSlice';
import chatLogReducer from './chatLogSlice';
import faqReducer from './faqSlice';
import settingsReducer from './settingsSlice';
import subscriptionsReducer from './subscriptionsSlice';
import summaryReducer from './summarySlice';

export const store = configureStore({
    reducer: {
        trainingData: trainingDataReducer,
        chatLog: chatLogReducer,
        faq: faqReducer,
        settings: settingsReducer,
        subscriptions: subscriptionsReducer,
        summary: summaryReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
