import React from "react";

interface HeaderProps {
    heading: string;
    paragraph?: string;
}

const Header: React.FC<HeaderProps> = ({ heading, paragraph }) => {
    return (
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
                className="mx-auto h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
            />
            <h2 className="mt-6 text-center  text-3xl font-extrabold text-gray-900">
                {heading}
            </h2>
        </div>
    );
};

export default Header;